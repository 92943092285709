<template>
  <div class="mt-4">
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Proyek</label>
        <vs-input class="w-full" :value="initData.spkAdjust.nama_proyek" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">No. SPK Adjust</label>
        <vs-input class="w-full" :value="initData.spkAdjust.nomor" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">No. SPK</label>
        <vs-input class="w-full" :value="initData.spkAdjust.no_spk" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Jenis SPK</label>
        <vs-input class="w-full" :value="initData.spkAdjust.jenis_spk" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Nama Rekanan</label>
        <vs-input class="w-full" :value="initData.spkAdjust.nama_rekanan" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Tanggal</label>
        <vs-input class="w-full" :value="initData.spkAdjust.tgl" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-2/3 w-full">
        <label class="ml-1 text-xs">Keterangan</label>
        <vs-textarea class="w-full" icon-pack="feather" icon="icon-map-pin" :value="initData.spkAdjust.keterangan" readonly/>
      </div>
      <div class="vx-col sm:w-1/3 w-full">
        <label class="ml-1 text-xs">Files</label>
        <vs-button color="warning" v-if="initData.spkAdjust.files_url.length > 0" type="filled" size="small" icon-pack="feather" icon="icon-file" class="px-2" @click="showFiles(initData.spkAdjust)">Lihat Files</vs-button>
        <div v-else class="text-sm">Tidak ada file.</div>
      </div>
    </div>
    <vs-divider class="mt-base mt-2 mb-2">Detail Pengurangan Termin</vs-divider>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">DPP Dikurangi</label>
        <vs-input class="w-full" :value="initData.spkAdjust.total_dpp | idr" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">PPN Dikurangi</label>
        <vs-input class="w-full" :value="initData.spkAdjust.total_jml_nilai_ppn | idr" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Total Nilai Dikurangi</label>
        <vs-input class="w-full" :value="initData.spkAdjust.total_jml_nilai | idr" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-6/12 w-full">
        <label class="ml-1 text-xs">PPh Dikurangi</label>
        <vs-input class="w-full" :value="initData.spkAdjust.total_jml_nilai_pph | idr" readonly/>
      </div>
      <div class="vx-col sm:w-6/12 w-full">
        <label class="ml-1 text-xs">Nominal ke Rekanan Dikurangi</label>
        <vs-input class="w-full" :value="initData.spkAdjust.total_jml_nilai_pokok | idr" readonly/>
      </div>
    </div>
    <div class="vx-row mt-base mb-3">
      <div class="vx-col sm:w-12/12 w-full">
        <vs-button color="primary" type="border" size="small" icon-pack="feather" icon="icon-external-link" class="px-2" @click="openDetailSpk">Lihat Detail Kontrak SPP</vs-button>
      </div>
    </div>
    <div class="vx-row float-right mt-5">
      <div class="vx-col w-full">
        <vs-button @click="next" icon-pack="feather" icon="icon-arrow-right" icon-after>Next</vs-button>
      </div>
    </div>

    <!-- Modals -->
    <FilesViewer :isActive.sync="modalFiles.active" :filesUrl="modalFiles.filesUrl"/>

  </div>
</template>

<script>
import FilesViewer from '@/views/components/files-viewer/FilesViewer'

export default {
  name: 'TabGeneral',
  props: ['isActive', 'initData'],
  components: {
    FilesViewer
  },
  data () {
    return {
      modalFiles: {
        filesUrl: [],
        active: false
      }
    }
  },
  methods: {
    openDetailSpk () {
      const routeData = { name: 'proyek.spk.show', params: { idSpk: this.initData.spkAdjust.id_spk } }
      const route = this.$router.resolve(routeData)
      window.open(route.href, '_blank')
    },

    showFiles (item) {
      this.modalFiles.filesUrl = item.files_url
      this.modalFiles.active = true
    },

    next () {
      const currentActiveTab = this.$store.state.approvals.spkAdjustApproval.activeTab
      this.$store.commit('approvals/spkAdjustApproval/SET_ACTIVE_TAB', currentActiveTab + 1)
    }
  }
}
</script>
